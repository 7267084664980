<template>
	<div class="details details--pricing details-pricing">
		<div class="details-loading" v-if="isLoading"><LoadingSpinner /></div>
		<div class="details-section">
			<div class="details-row details-row--col-4">
				<detail label="Fixed Rate" :value="pricing.fixedRate | percentage" :border="false" size="medium" />
				<detail label="Spread" :value="pricing.spread" :border="false" size="medium" />
				<detail label="Maturity" :value="pricing.maturity" suffix="yr" :border="false" size="medium" />
				<detail label="Amortization" :value="pricing.amortization" suffix="yr" :border="false" size="medium" />
			</div>
			<div class="details-row details-row--col-4" v-if="opportunity.existingLoan">
				<detail label="Existing Rate" :value="pricing.existingRate" :border="false" suffix="%" size="medium" />
				<detail label="Existing Rate Expiration" :value="getStartDate(pricing.dateStart)" :border="false" size="medium" />
				<detail label="Existing Amortization" :value="pricing.existingAmortization" :border="false" suffix="yr" size="medium" />
			</div>
			<div class="details-row details-row--col-5" :class="{'details-row--col-5': opportunity.existingLoan || !isAdmin, 'details-row--col-5': !opportunity.existingLoan && isAdmin}">
				<detail label="P&I/MO" :value="pricing.principalInterest | currency(false, 0)" :border="false" size="medium" />
				<detail label="dv01" :value="pricing.dv01 | currency" :border="false" size="medium" v-if="isAdmin" />
				<detail label="Fee Income" :value="pricing.feeIncomeAmount | currency(false, 0)" :border="false" size="medium" />
				<detail label="VAR" :value="pricing.varAmount | currency(false, 0)" :border="false" size="medium" v-if="isAdmin" />
				<detail label="Start Date" :value="pricing.dateStart | enums('DateStart')" :border="false" size="medium" v-if="!opportunity.existingLoan" />
				<detail label="Optimize Your Profits" :border="false">
					<a @click="getProfitAnalysis()" class="button button--primary button--unelevated button--tiny">Get Profit Analysis</a>
				</detail>
			</div>
		</div>
	</div>
</template>

<script>
import Detail from '@/components/Detail'
import LoadingSpinner from '@/components/LoadingSpinner'
import opportunity from '../lib/store/modules/opportunity'
import { addMonths, format, parseISO } from 'date-fns'

export default {
	name: 'DetailsPricing',
	components: {
		Detail,
		LoadingSpinner,
	},
	props: {
		opportunity: {
			type: Object,
			default: () => ({}),
		},
		pricing: {
			type: Object,
			default: () => ({}),
		},
		idx: {
			type: Number|String,
			default: null,
		},
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		isAdmin() {
			return !!this.$store.getters['user/isAdmin']
		},
	},
	methods: {
		getStartDate(dateStart) {
			let startDate = format(parseISO(this.opportunity.dateModified || this.opportunity.dateCreated), 'MMMM yyyy')
			if (dateStart) {
				startDate = format(addMonths(parseISO(this.opportunity.dateModified || this.opportunity.dateCreated), dateStart), 'MMMM yyy')
			}

			return startDate
		},
		async getProfitAnalysis() {
			this.isLoading = true
			try {
				let res = await this.$store.dispatch('opportunity/sendAnalysis', { opportunity: this.opportunity, idx: this.idx })
				this.$confirm(`Your ARC Rep will reach out to you shortly via email with your profitability analysis.`, () => {}, {
					acceptLabel: `Close`,
					cancelLabel: ``,
					showCancel: false,
					title: `Thank you for your profit optimization request.`,
				})
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			} finally {
				this.isLoading = false
			}

		},
	},
}
</script>

<style scoped lang="scss">
.details {
	&-section {
		position: relative;
	}

	&-loading {
		left: 0;
		top: 0;
		position: absolute;
		background-color: rgba(255,255,255,0.35);
		backdrop-filter: blur(1px);
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		z-index: 10;
	}
}
</style>
