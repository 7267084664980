<template>
	<div class="detail" :class="{'detail--border': border, 'detail--size-medium': size == 'medium', 'detail--size-small': size == 'small'}">
		<div class="detail__label" v-html="label" v-if="label"></div>
		<div class="detail__value" v-if="value">{{ value }}{{ value != undefined ? suffix : null }}</div>
		<div class="detail__content" v-if="$slots.default"><slot></slot></div>
	</div>
</template>

<script>
export default {
	name: 'Detail',
	props: {
		label: String,
		value: String|Number,
		suffix: String|Number,
		border: {
			type: Boolean,
			default: true,
		},
		size: {
			type: String,
			default: 'normal',
		},
	},
}
</script>

<style scoped lang="scss">
.detail {
	$detail: &;
	@include modules.gutter('padding-bottom');
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;

	&--border {
		border-bottom: 1px solid modules.color_('border', 'light');

		@include modules.media-query('phone') {
			border: 0;
		}
	}

	&__label {
		@include modules.fontSize(16px);
		font-weight: bold;
		line-height: 24px;
		text-transform: uppercase;

		#{$detail}--size-medium & {
			@include modules.fontSize(13px);
		}
	}

	&__value {
		@include modules.fontSize(18px);
		letter-spacing: -0.6px;
		line-height: 20px;

		&:empty {
			&:before {
				@include modules.fontSize(15px);
				color: #d1d1d1;
				content: '(empty)';
				line-height: 20px;
			}
		}
	}

	&__content {
		padding-top: 4px;
	}
}
</style>
