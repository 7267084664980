<template>
	<router-view id="app" />
</template>

<script>
export default {
	name: 'app',
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	mounted() {
		// TODO: Auth Check
	},
	methods: {
		showRefresh(evt) {
			this.$notice('New version available!', () => {
				evt.detail.postMessage({action: 'skipWaiting'})
			}, {
				actionLabel: 'Click Here to Update',
				timeout: 10000,
			})
		},
	},
	metaInfo: () => ({
		title: 'ARC Pricing',
		titleTemplate: '%s | ARC Pricing',
		meta: [
			{
				charset: 'utf-8',
			},
			{
				'http-equiv': 'X-UA-Compatible',
				content: 'IE-edge',
			},
			{
				name: 'description',
				content: 'ARC Pricing Application',
			},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=5',
			},
		],
		script: [
			{
				type: 'module',
				src: 'https://unpkg.com/ionicons@4.6.4-1/dist/ionicons/ionicons.esm.js',
				async: true,
				defer: true,
			},
			{
				src: `${process.env.PORTAL_URL || process.env.VUE_APP_PORTAL_URL}component/portal-header.js`,
			},
		],
	})
}
</script>


<style lang="scss">
@import 'styles/partials';

#app {
	font-family: modules.$font-family-primary;
	min-height: 100vh;
}
</style>
