<template>
	<header class="main-header">
		<div class="main-header__logo">
			<img src="@/assets/ARC-logo.svg" class="main__header--logo" alt="ARC Pricing" />
			<span class="main-header__logo-label">ARC PRICING</span>
		</div>
		<main-nav class="main-header__nav" />
	</header>
</template>

<script>
import MainNav from '@/components/MainNav'

export default {
	name: 'MainHeader',
	components: {
		MainNav,
	},
}
</script>

<style scoped lang="scss">
.main-header {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	&__logo {
		max-width: 54px;
		display: flex;
		align-items: center;
		@include modules.gutter('margin-left', .5);

		img {
			max-width: 100%;
			position: relative;
			top: -2px;
		}

		&-label {
			@include modules.fontSize(20px);
			@include modules.gutter('padding-left', .5);
			font-weight: 600;
			color: modules.color_('primary','light');
			letter-spacing: 2.5px;
			white-space: nowrap;
		}
	}
}
</style>
