<template>
	<div class="partial partial--presentation presentation">
		<div class="presentation-header">
			<div class="presentation-header__content">
				<div class="presentation-header__logo" v-if="presentation.organization">
					<img :src="presentation.organization.logo" v-if="presentation.organization.logo" :alt="presentation.organization.name" />
				</div>
				<div class="presentation-header__label">
					<!-- Fixed Rate Loan Opportunity -->
					<strong>Rates Updated: {{ (presentation.dateModified || presentation.dateCreated) | date }}</strong>
				</div>
				<div class="presentation-header__actions">
					<a class="button button--text button--medium utton--primary presentation-header__action" @click="update()">
						<span class="button__label">
							Update Rates
						</span>
						<span class="button__icon">
							<ion-icon name="refresh"></ion-icon>
						</span>
					</a>

					<a class="button button--unelevated button--secondary button--medium" @click="download()">
						<span class="mdc-button__label">Download</span>
						<span class="mdc-button__icon">
							<ion-icon name="download"></ion-icon>
						</span>
					</a>
					<!-- <small>Last updated: {{ (presentation.dateCreated || presentation.dateModified) | date }} </small> -->
				</div>
			</div>
			<loading-line class="presentation-header__loading" v-if="isLoading" />
		</div>

		<div class="presentation-body">
			<!-- PDF COVER SHEET -->
			<div class="presentation-page pdf-only">
				<div class="presentation-cover" v-if="presentation.organization">
					<img :src="presentation.organization.logo" v-if="presentation.organization.logo" :alt="presentation.organization.name" />
					<h1 v-if="!presentation.organization.logo">{{ presentation.organization.name }}</h1>

					<h2 style="font-size: 20px !important; font-weight: bold;">Fixed Rate Loan Opportunity</h2>

					<h4 style="font-size: 16px !important; font-weight: normal;">Assumable Rate Conversion (ARC) Loan</h4>

					<h3 style="font-size: 16px !important; font-weight: normal;">for</h3>

					<h2 style="font-size: 18px !important;">{{ presentation.name }}</h2>

				</div>
			</div>
			<!-- /PDF Cover Sheet -->

			<!-- PDF PAGE 1 -->
			<div class="presentation-page">
				<div class="presentation-body__title" v-if="presentation.organization">
					<span>
						<h1 class="screen-only">{{ presentation.organization.name }}</h1>
						<img :src="presentation.organization.logo" v-if="presentation.organization.logo" class="pdf-only pdf-only--inline" :alt="presentation.organization.name" />
					</span>
					<div class="text--center">
						<h2>Fixed Rate Pricing</h2>
					</div>
					<div class="presentation-body__meta">
						<h2>{{ presentation.name }}</h2>
					</div>
				</div>

				<div class="presentation-body__data">
					<div class="presentation-body__data-item">
						<h2>Principal Loan Amount</h2>
						<h3>{{ presentation.loanAmount | currency(false, 0) }}</h3>
					</div>
				</div>

				<div class="table-wrapper--responsive">
					<table class="presentation-body__table" :class="{'presentation-body__table--existing': presentation.existingLoan}" border="0" cellspacing="0">
						<thead v-if="pricingOptions.length">
							<tr class="table-header">
								<td class="table-header__item" v-if="presentation.existingLoan">Existing Am</td>
								<td class="table-header__item" v-if="presentation.existingLoan">Existing Rate</td>
								<td class="table-header__item">{{ presentation.existingLoan ? `Existing Expiration` : `Approx. Start Date` }}</td>
								<td class="table-header__item">Fixed Term/Am</td>
								<td class="table-header__item">Fixed Rate</td>
								<td class="table-header__item">Hedge-Based Pricing</td>
								<td class="table-header__item">Approximate Monthly P&amp;I</td>
							</tr>
						</thead>
						<tbody>
							<tr class="table-row" :class="{'table-row--primary': option.isPrimary}" v-for="(option, key) in pricingOptions" :key="key">
								<td class="table-row__item" v-if="presentation.existingLoan">
									{{ option.existingAmortization || 0 }}yr
								</td>
								<td class="table-row__item" v-if="presentation.existingLoan">
									{{ option.existingRate / 100 | percentage }}
								</td>
								<td class="table-row__item">
									{{ getStartDate(option) }}
								</td>
								<td class="table-row__item">
									{{ option.maturity }}yr / {{ option.amortization }}yr
								</td>
								<td class="table-row__item">
									{{ option.fixedRate | percentage }}
								</td>
								<td class="table-row__item">
									{{ option.maturity}}yr Swap + {{ option.spread/ 10000 | percentage }}
								</td>
								<td class="table-row__item">
									{{ option.principalInterest | currency(false, 0) }}
								</td>
							</tr>
							<tr colspan="5" v-if="!pricingOptions.length">
								<td class="table-row__item table-row__item--alert">Pricing options missing. Please ask for an updated presentation.</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div class="presentation-body__section text--left">
					<div class="presentation-body__chart--libor">
						<figcaption>
							<p>Rates are near historical lows</p>
							<p>This chart shows current market expectations of future daily SOFR paid monthly in red dots</p>
							<p>By fixing the loan rate, a borrower creates cash flow certainty in a rising interest rate environment</p>
						</figcaption>
						<img src="~@/assets/presentation-chart_sofr-010824.png" alt="SOFR Historical" />
						<!-- <img src="~@/assets/presentation-chart_libor.png" alt="Libor Historical" /> -->
					</div>
					<br />
					<ul>
						<li><small>Borrower’s fixed rate will be established at loan closing (a forward rate lock may be available)</small></li>
						<li><small>To obtain the fixed rate the Borrower will sign an addendum to the bank’s loan documents called a Rate Conversion Agreement (a sample copy is available for Borrower’s review)</small></li>
						<li><small>Rates shown are for indicative purposes only. The final fixed rate will be established on the day of loan closing. Prevailing rates will be made available in the interim upon request.</small></li>
					</ul>

				</div>
			</div>

			<!-- PDF PAGE 2 -->
			<div class="presentation-page">

				<div class="presentation-body__title pdf-only">
					<span v-if="presentation.organization">
						<h1 class="screen-only">{{ presentation.organization.name }}</h1>
						<img :src="presentation.organization.logo" v-if="presentation.organization.logo" class="pdf-only pdf-only--inline" :alt="presentation.organization.name" />
					</span>
					<div class="text--center">
					</div>
					<div class="presentation-body__meta">
						<h2>{{ presentation.name }}</h2>
					</div>
				</div>

				<hr class="screen-only" />

				<div class="presentation-body__section" v-if="pricingOptions.length">
					<p>The breakeven prepayment provision associated with a hedge is a borrower-friendly or equable prepayment calculation because it allows the borrower to collect a fee if rates rise and the loan/hedge combination is prepaid. This prepayment provision makes sense for borrowers that hold any of the following expectations:</p>
					<ol>
						<li>The borrower believes that interest rates will rise over the contractual life of the loan;</li>
						<li>The borrower expects to hold the loan for a substantial portion of the contractual loan term; or</li>
						<li> The borrower expects to have future financing needs on alternative or additive projects/properties and would like to maintain the economic value of the fixed rate established on this loan for those possible future projects/properties.</li>
					</ol>

					<p>Subject Loan: A {{ presentation.loanAmount | currency(false, 0) }} loan is structured as a {{ primaryPricing.amortization }} years amortizing with a {{ primaryPricing.maturity }} years fixed rate, with a {{ primaryPricing.fixedRate | percentage }} fixed rate and the initial swap rate or hedge rate (synonymous terms) is {{ primaryPricing.hedgeRate | percentage }}.</p>

					<table class="table-notes" cellspacing="4" v-if="adjustmentTable.length">
						<tbody>
							<tr>
								<td class="table-note" style="width: 50%; border: 1px solid #002D72; border-radius: 5px; padding: 0.5rem;vertical-align: top; font-size: 8px;" v-html="`If ${pluralize(notesKey, 'year')} into the loan, the then current ${adjustmentTable.length - notesKey} year hedge rate is 25 bps lower than the starting year hedge rate and the borrower prepays the entire loan, the borrower will pay <strong>${ $options.filters.currency((Math.abs(adjustmentTable[notesKey][2]) || 0), false, 0) }</strong>. Partial prepayments work similarly on a proportional basis. However, the borrower and lender will have the option to apply the loan to a new project/ property and the repayment will not apply.`"></td>
								<td class="table-note" style="width: 50%; border: 1px solid #0960BB; border-radius: 5px; padding: 0.5rem;vertical-align: top; font-size: 8px;" v-html="`If ${pluralize(notesKey, 'year')} years into the loan, the then current ${adjustmentTable.length - notesKey}  year hedge rate is 25 bps higher than the starting hedge rate and the borrower prepays the entire loan, the borrower will receive <strong>${ $options.filters.currency((Math.abs(adjustmentTable[notesKey][4]) || 0), false, 0) }</strong>. Partial prepayments work similarly on a proportional basis.`"></td>
							</tr>
						</tbody>
					</table>

					<table style="width: 100%">
						<tbody>
							<tr>
								<td class="text--center" rowspan="4" style="width:35%">
									<a href="https://southstatecorrespondent.com/the-arc-pre-payment-provision-for-borrowers/" target="_blank" class="screen-only">Click to Watch Video</a>
								</td>
								<td>
									<table style="width: 100%">
										<tbody>
											<tr>
												<td><strong>Initial Hedge Rate:</strong></td>
												<td>{{ primaryPricing.hedgeRate | percentage }}</td>
											</tr>
											<tr>
												<td><strong>Loan Spread:</strong></td>
												<td>{{ (primaryPricing.spread / 10000) | percentage }}</td>
											</tr>
											<tr>
												<td></td>
												<td><hr class="hr" /></td>
											</tr>
											<tr>
												<td><strong>All-in Fixed Rate:</strong></td>
												<td>{{ primaryPricing.fixedRate | percentage }}</td>
											</tr>
										</tbody>
									</table>
								</td>
								<td class="text--center" rowspan="4" style="width:35%">
									<img src="~@/assets/presentation-qr_code.png" class="presentation__qr" alt="" />
								</td>
							</tr>
						</tbody>
					</table>
					<br />
					<h2 class="text--center">Prepayment Hedge Rate vs. Initial Hedge Rate</h2>

					<div class="table-wrapper--responsive table-wrapper--responsive-presentation">
						<table class="presentation-table" border="0" cellspacing="0">
							<thead>
								<tr>
									<th>Remaining Term</th>
									<th>-75 bps</th>
									<th>-50 bps</th>
									<th>-25 bps</th>
									<th>0 bps</th>
									<th>+25 bps</th>
									<th>+50 bps</th>
									<th>+75 bps</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, key) in adjustmentTable" :key="key" :class="{'highlighted': (notesKey == key)}">
									<td>{{ pluralize(adjustmentTable.length - key, 'year') }}</td>
									<td class="tooltip" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key} year hedge rate is 75 bps lower than the starting year hedge rate and the borrower prepays the entire loan, the borrower will pay ${ $options.filters.currency((Math.abs(row[0]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis. However, the borrower and lender will have the option to apply the loan to a new project/ property and the repayment will not apply.`">{{ row[0] | currency(false, 0) }}</td>
									<td class="tooltip" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key} year hedge rate is 50 bps lower than the starting year hedge rate and the borrower prepays the entire loan, the borrower will pay ${ $options.filters.currency((Math.abs(row[1]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis. However, the borrower and lender will have the option to apply the loan to a new project/ property and the repayment will not apply.`">{{ row[1] | currency(false, 0) }}</td>
									<td class="tooltip" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key} year hedge rate is 25 bps lower than the starting year hedge rate and the borrower prepays the entire loan, the borrower will pay ${ $options.filters.currency((Math.abs(row[2]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis. However, the borrower and lender will have the option to apply the loan to a new project/ property and the repayment will not apply.`">{{ row[2] | currency(false, 0) }}</td>
									<td> - </td>
									<td class="tooltip" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key}  year hedge rate is 25 bps higher than the starting hedge rate and the borrower prepays the entire loan, the borrower will receive ${ $options.filters.currency((Math.abs(row[4]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis.`">{{ row[4] | currency(false, 0) }}</td>
									<td class="tooltip tooltip--right" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key}  year hedge rate is 50 bps higher than the starting hedge rate and the borrower prepays the entire loan, the borrower will receive ${ $options.filters.currency((Math.abs(row[5]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis.`">{{ row[5] | currency(false, 0) }}</td>
									<td class="tooltip tooltip--right" :class="{'tooltip--bottom': (key < (adjustmentTable.length / 2))}" :data-title="`If ${pluralize(key, 'year')} into the loan, the then current ${adjustmentTable.length - key}  year hedge rate is 75 bps higher than the starting hedge rate and the borrower prepays the entire loan, the borrower will receive ${ $options.filters.currency((Math.abs(row[6]) || 0), false, 0) }. Partial prepayments work similarly on a proportional basis.`">{{ row[6] | currency(false, 0) }}</td>
								</tr>
							</tbody>
						</table>

					</div>
				</div>
			</div>

			<!-- PDF PAGE 3 -->
			<div class="presentation-page">

				<div class="presentation-body__title pdf-only" v-if="presentation.organization">
					<span>
						<h1 class="screen-only">{{ presentation.organization.name }}</h1>
						<img :src="presentation.organization.logo" v-if="presentation.organization.logo" class="pdf-only pdf-only--inline" :alt="presentation.organization.name" />
					</span>
					<div class="text--center">
					</div>
					<div class="presentation-body__meta">
						<h2>{{ presentation.name }}</h2>
					</div>
				</div>
				<hr class="screen-only" />
				<div class="presentation-body__section">
					<h3>Considerations for a borrower when contemplating ARC:</h3>
					<ul>
						<li>Does the borrower intend to hold the loan for a substantial portion of the contractual term?</li>
						<li>May the borrower need to transfer the hedge to another loan with the lender in the future?</li>
						<li>Can the borrower's cash flow support debt service on a variable basis in a rising rate environment?</li>
						<li>Are rates more likely to rise or fall in the future? Upon loan/hedge prepayment, if rates rise, the borrower is paid a prepayment fee, if rates fall, the borrower must pay a prepayment fee.</li>
					</ul>
					<br>
					<h3>Historical 10-Year Treasury Rate</h3>

					<div class="presentation-body__chart">
						<img src="~@/assets/presentation-chart_swap-010824.jpg" alt="Swap Rate Chart" />
					</div>

					<figcaption>
						<p>Note that this prepayment provision is in line with a borrower's motivation for fixed rate protection. If rates rise as expected, the borrower will collect a fee upon partial or full prepayment.</p>
					</figcaption>
				</div>
			</div>
			<hr class="screen-only" />
			<!-- PDF PAGE 4 -->
			<div class="presentation-page">
				<div class="presentation-body__title pdf-only" v-if="presentation.organization">
					<span>
						<h1 class="screen-only">{{ presentation.organization.name }}</h1>
						<img :src="presentation.organization.logo" v-if="presentation.organization.logo" class="pdf-only pdf-only--inline" :alt="presentation.organization.name" />
					</span>
					<div class="text--center">
					</div>
					<div class="presentation-body__meta">
						<h2>{{ presentation.name }}</h2>
					</div>
				</div>

				<div class="presentation-body__section">
					<h3>FAQs</h3>
					<p>
						<strong>What are the advantages of the ARC program over swaps offered by national banks?</strong><br />
						The ARC program does not result in a derivative for the borrower, does not require complex documentation, and most importantly creates a constant and predicable payment obligation.
					</p>
					<p>
						<strong>How is the fixed rate determined?</strong><br />
						The fixed rate is equal to the hedge rate plus the credit margin that is agreed to by the community bank and the borrower.
					</p>
					<p>
						<strong>When is the final fixed rate determined?</strong><br />
						The final fixed rate is established at the time of the loan closing. The fixed rate in this presentation is an indicative rate and will move up and down between now and closing.
					</p>
					<p>
						<strong>Can the rate be locked before the closing?</strong><br />
						Yes, the fixed rate may be locked before the loan closes or before the loan is fully funded. The borrower may be required to post collateral during the forward period.
					</p>
					<p>
						<strong>Can partial prepayments be made?</strong><br />
						Yes, partial prepayments of $100,000 or more are permitted and are subject to the symmetrical yield maintenance provision.
					</p>
					<p>
						<strong>What if the borrower wants more flexibility to prepay liabilities?</strong><br />
						The community bank may extend two loans; one  ARC loan and one non-ARC loan.  The non-ARC loan can be prepaid without penalty and may be a shorter maturity with a fixed or floating rate.
					</p>
					<p>
						<strong>What legal documents does the borrower have to sign?</strong><br />
						In addition to the community bank’s loan closing documents, the borrower will sign a short addendum to the promissory note called Rate Conversion Agreement (RCA), which will fix the borrower’s loan rate.
					</p>
					<p>
						<strong>How will periodic loan payments work?</strong><br />
						The borrower will make periodic fixed-rate payments directly to the community bank.
					</p>
					<p>
						<strong>Are there any borrower eligibility requirements for the ARC program?</strong><br />
						Yes, the borrower must be an Eligible Contract Participant, which requires that either the borrowing entity or each of the owners of the borrowing entity have a net worth of at least $1 million.
					</p>
					<p>
						<strong>What if the borrower must sell the property that is securing the fixed rate loan?</strong><br />
						The ARC program is fully portable from project to project regardless of the size of the loan or nature of the collateral subject to credit approval.
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { addMonths, format, parseISO } from 'date-fns'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting'
import { Chart } from 'highcharts-vue'
import jsPDF from 'jspdf'
import { PDFDocument } from 'pdf-lib'
import { kebabCase } from 'lodash'
import LoadingLine from '@/components/LoadingLine'

exporting(Highcharts)

export default {
	name: 'PartialPresentation',
	components: {
		Chart,
		LoadingLine,
	},
	data: () => ({
		isLoading: false,
		liborChartRef: null,
		ratesChartRef: null,
	}),
	async asyncData({ store, route }) {
		const { opportunityId, presentationId } = route.params
		await store.dispatch('opportunity/getPresentation', { opportunityId, presentationId })
	},
	async mounted() {
	},
	computed: {
		presentation() {
			return this.$store.getters['opportunity/presentation']
		},
		startDate() {
			let startDate = format(parseISO(this.presentation.dateCreated), 'MMMM yyyy')
			const { pricingOptions } = this.presentation
			if (pricingOptions) {
				const pricingOption = pricingOptions.find(po => po.isPrimary)
				if (pricingOption) {
					const { dateStart } = pricingOption
					if (dateStart) {
						startDate = format(addMonths(parseISO(this.presentation.dateCreated), dateStart), 'MMMM yyy')
					}
				}
			}

			return startDate
		},
		liborChart() {
			return {
				title: {
					text: `Libor`,
				},
				series: [
					{
						data: [1, 2, 3]
					}
				]
			}
		},
		ratesChart() {
			return {
				title: {
					text: `Historical 10 Year Treasury Rates`,
				},
				series: [
					{
						data: [1, 2, 3]
					}
				]
			}
		},
		pricingOptions() {
			return (this.presentation.pricingOptions || []).filter(po => po.active)
		},
		primaryPricing() {
			const primary = this.pricingOptions.find(po => po.isPrimary) ||  this.pricingOptions[0]
			if (!primary) return {}
			primary.hedgeRate = primary.fixedRate - Number(primary.spread) / 10000
			return primary || {}
		},
		adjustmentTable() {
			const table = []
			for (const key in this.primaryPricing.adjustmentTable) {
				const row = this.primaryPricing.adjustmentTable[key]
				if (key != '0') {
					table.push(row)
				}
			}

			return table.reverse()
		},
		notesKey() {
			return Math.min(Math.max(this.adjustmentTable.length - 3, 2), 3)
		},
	},
	methods: {
		async download() {

			const savePage = (page, count = 0) => {
				let pdf = new jsPDF('p', 'pt', 'letter')
				page.style.width = 550+'px'
				page.style.fontFamily = `Helvetica, CeraPro, Avenir, Lato, arial`
				page.style.fontSize = '9px'
				pdf.setFont('helvetica', 'normal');
				pdf.setFontSize(9)

				return new Promise((resolve, reject) => {
					pdf.html(page, {
						margin: [16, 16, 16, 16],
						callback: (doc) => {
							doc.setFont('Helvetica', 'Arial', 'normal')
							doc.setFontSize(12)
							resolve(doc)

							page.style = {}
						},
						html2canvas: { scale: 1 },
						x: 16,
						y: 16,
						// y: (792 * count),
					})
				})
			}

			let pages = document.querySelectorAll('.presentation-page')

			let i = 0
			let docs = []
			for (const page of pages) {
				page.classList.add('presentation-page--pdf')
				let p = await savePage(page, i)
				let b = await p.output('arraybuffer')
				docs.push(b)
				await new Promise((resolve, reject) => setTimeout(resolve, 100))
				page.classList.remove('presentation-page--pdf')
				i++
			}

			const pdfDoc = await PDFDocument.create()

			for (const doc of docs) {
				const p = await PDFDocument.load(doc)
				const copiedPages = await pdfDoc.copyPages(p, p.getPageIndices())
				for (const cp of copiedPages) {
					await pdfDoc.addPage(cp)
				}
			}

			// Force Download
			const saved = await pdfDoc.save()
			const pdfUrl = URL.createObjectURL(
				new Blob([saved], { type: 'application/pdf'})
			)

			let a = document.createElement('a')
			document.body.appendChild(a)
			a.href = pdfUrl
			a.download = `ARC_Presentation_${(this.presentation.name).replace(/\s/,'-')}_${format((new Date()), 'EEE-dd-yyyy')}.pdf`
			a.click()
			URL.revokeObjectURL(pdfUrl)
			document.removeChild(a)
		},
		ratesChartLoaded(chart) {
			this.ratesChartRef = chart
		},
		liborChartLoaded(chart) {
			this.liborChartRef = chart
		},
		async update() {
			this.isLoading = true
			const { opportunityId, presentationId } = this.$route.params
			this.$store.dispatch('opportunity/refreshPresentation', { opportunityId, presentationId} )
				.then((res) => {
				})
				.catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		getStartDate(pricingOption) {
			let startDate = format(parseISO(this.presentation.dateCreated), 'MMMM yyyy')
			if (pricingOption) {
				const { dateStart } = pricingOption
				if (dateStart) {
					startDate = format(addMonths(parseISO(this.presentation.dateCreated), dateStart), 'MMMM yyy')
				}
			}

			return startDate
		},
		pluralize(value, label) {
			if (value == 1) return `${value} ${label}`
			return `${value} ${label}s`
		}
	},
	metaInfo: () => ({
		title: `ARC Pricing Presentation`,
	}),
}
</script>

<style scoped lang="scss">
.presentation {
	$presentation: &;
	padding: 0;
	max-width: 100%;
	background: modules.color_('background', 'light');
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.pdf-only {
		display: none;

		&--flex {
			display: none;
		}

		&--block {
			display: none;
		}

		&--inline {
			display: none;
		}
	}

	&-page {
		width: 100%;

		&--pdf {
			border: 1px solid modules.color_('primary');
			max-width: 600px;
			height: 740px;
			overflow: hidden;
			padding: 12px;

			h2 {
				font-size: 10px !important;
			}

			h3 {
				font-size: 12px !important;
			}

			.table-row__item {
				font-size: 10px !important;
			}

			.screen-only {
				display: none;
			}

			.pdf-only {
				&--flex {
					display: flex;
				}

				&--block {
					display: block;
				}

				&--inline {
					display: inline;
				}
			}

			.table-wrapper--responsive-presentation {
				max-height: 272px;
				overflow-y: hidden;
			}

			.presentation__qr {
				max-width: 60px !important;
			}

		}

		&--disclaimer {
			&#{$presentation}-page--pdf {
				display: none;
			}
		}
	}

	&-header {
		background-color: modules.color_('background');
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.12);
		position: relative;

		&__loading {
			bottom: 0;
			position: absolute;
			left: 0;
		}

		&__content {
			@include modules.gutter('padding-left', 1);
			@include modules.gutter('padding-right', 1);
			@include modules.gutter('padding-top', 1);
			@include modules.gutter('padding-bottom', 1);
			min-height: 100px;
			align-items: center;
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			@include modules.media-query('phone') {
				@include modules.gutter('padding', .2);
				grid-template-columns: repeat(1, 1fr);
			}

			@include modules.media-query('tablet') {
				grid-template-columns: repeat(3, auto);
			}
		}

		&__logo {

			img {
				max-width: 200px;
				max-height: 60px;
				height: auto;
				width: auto;
			}

			@include modules.media-query('phone') {
				display: none;
			}
		}

		&__label {
			@include modules.fontSize(13px);
			color: modules.color_('text', 'medium');
			text-transform: uppercase;
			letter-spacing: 1px;
			text-align: center;

			@include modules.media-query('desktop') {
				text-align: center;
			}
		}

		&__actions {
			flex-direction: row;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.button {
				height: 50px;
			}

			@include modules.media-query('phone') {
				justify-content: space-around;
			}
		}

		&__action {
			padding: 1.5rem 1.75rem;
			line-height: 1;
			white-space: nowrap;

			small {
				display: block;
				letter-spacing: 0;
			}

			@include modules.media-query('phone') {
				margin-right: 0.5rem;
			}
		}
	}

	&-body {
		@include modules.gutter('margin-top', 1.5);
		@include modules.gutter('margin-bottom', 1.5);
		@include modules.gutter('padding', 1.5);
		background: modules.color_('background');
		box-shadow: 0 4px 20px 0 rgba(0,0,0,0.12);
		max-width: modules.$container-max-width;
		overflow: auto;
		width: 100%;
		margin: 0 auto;

		&--pdf {
			max-width: 600px;

			h2 {
				font-size: 10px !important;
			}

			h3 {
				font-size: 12px !important;
			}

			.table-row__item {
				font-size: 10px !important;
			}
		}

		&__title {
			@include modules.gutter('padding-bottom', 1);
			align-items: center;
			display: grid;
			justify-content: space-between;
			border-bottom: 1px solid modules.color_('border', 'light');
			grid-template-columns: repeat(3, 1fr);

			img {
				max-height: 40px;
				max-width: 200px;
			}

			@include modules.media-query('phone') {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				width: 100%;
			}

			h1, h2 {
				margin: 0;
				font-size: 1.17em;

				@include modules.media-query('phone') {
					font-size: revert;
				}
			}

			&--pdf-only {
				display: none;

				#{$presentation}-page--pdf & {
					display: flex;
				}
			}

			#{$presentation}-page--pdf & {
				@include modules.gutter('padding-bottom', 0.5);
				//align-items: flex-end;
			}
		}

		&__meta {
			// display: flex;
			// flex-direction: column;
			// justify-content: flex-end;
			text-align: right;
			line-height: 1.5;

			@include modules.media-query('phone') {
				text-align: center;
			}
		}

		&__data {
			@include modules.gutter('padding-top', .75);
			@include modules.gutter('padding-bottom', .75);
			border-bottom: 1px solid modules.color_('border', 'light');
			display: grid;
			// grid-template-columns: repeat(2, 1fr);

			@include modules.media-query('phone') {
				grid-template-columns: repeat(1, 1fr);
			}

			#{$presentation}-page--pdf & {
				@include modules.gutter('padding-top', .5);
				@include modules.gutter('padding-bottom', .5);
			}

			&-heading {
				grid-column: span 2;
				text-align: center;
			}

			&-item {
				text-align: center;

				@include modules.media-query('phone') {
					@include modules.gutter('margin-bottom', .75);
				}

				h2 {
					text-transform: uppercase;
					@include modules.fontSize(13px);
					letter-spacing: 1px;
				}

				h3 {
					@include modules.fontSize(30px);
					@include modules.gutter('margin-top', .5);
					@include modules.gutter('margin-bottom', .5);
					font-weight: normal;

					#{$presentation}-page--pdf & {
						@include modules.gutter('margin-top', 0);
						@include modules.gutter('margin-bottom', .25);
						font-size: 14px !important;
					}
				}
			}
		}

		&__table {

			width: 100%;

			&--existing {
				.table-header,
				.table-row {
					grid-template-columns: repeat(7, 1fr) !important;
				}
			}

			.table-header {
				@include modules.gutter('padding-top', .5);
				@include modules.gutter('padding-bottom', .5);
				border-bottom: 1px solid modules.color_('border', 'light');
				display: grid;
				grid-template-columns: repeat(5, 1fr);

				@include modules.media-query('phone') {
					display: none;
				}

				#{$presentation}-page--pdf & {
					@include modules.gutter('padding-top', .25);
					@include modules.gutter('padding-bottom', .25);
				}

				&__item {
					@include modules.fontSize(12px);
					text-align: center;
					text-transform: uppercase;
					color: modules.color_('text', 'medium');

					#{$presentation}-page--pdf & {
						font-weight: bold;
						text-align: center;
						text-transform: none;
						font-size: 8px;
					}
				}
			}

			.table-row {
				@include modules.gutter('padding-top', .25);
				@include modules.gutter('padding-bottom', .25);
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				border-bottom: 1px solid modules.color_('border', 'light');

				&--primary {
					font-weight: 500;
				}

				@include modules.media-query('phone') {
					grid-template-columns: 1fr;
				}

				#{$presentation}-page--pdf & {
					@include modules.gutter('padding-top', .05);
					@include modules.gutter('padding-bottom', .05);
				}


				&__item {
					@include modules.fontSize(18px);
					@include modules.gutter('padding-top', .5);
					@include modules.gutter('padding-bottom', .5);
					text-align: center;

					#{$presentation}-page--pdf & {
						@include modules.gutter('padding-top', .1);
						@include modules.gutter('padding-bottom', .1);
						font-size: 7px;
					}

					&--alert {
						background-color: #fce28d;
						border: 1px solid #FFBF40;
					}
				}

				&--0 {
					font-weight: 500;
				}
			}
		}

		&__section {
			@include modules.gutter('padding-top', 1);
			@include modules.gutter('padding-bottom', 1);

			p, ul, ol {
				color: modules.color_('text');
				line-height: 1.5;
				#{$presentation}-page--pdf & {
					color: modules.color_('text');
					@include modules.fontSize(11px);
					line-height: initial;
				}
			}

			figcaption {
				@include modules.gutter('padding-left', 1);
				@include modules.gutter('padding-right', 1);
			}

			figcaption p {
				color: modules.color_('text');
				font-weight: 500;

				#{$presentation}-page--pdf & {
					font-size: 9px;
				}
			}

			#{$presentation}-page--pdf & {
				@include modules.gutter('padding-top', 0.25);
				@include modules.gutter('padding-bottom', 0.25);
			}
		}

		&__chart {
			@include modules.gutter('margin-bottom', 1);
			@include modules.gutter('padding', 0.25);
			background-color: modules.color_('background');
			box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
			position: relative;

			&--libor {
				display: flex;
				align-items: center;
				flex-direction: column-reverse;

				@include modules.media-query('desktop') {
					flex-direction: row;
					text-align: left;
				}

				img {
					max-width: 100%;

					@media only screen and (min-width: 840px) {
						max-width: 60%;
					}

					@media only screen and (min-width: 1280px) {
						max-width: 66%;
					}

					#{$presentation}-page--pdf & {
						max-width: 370px !important;
					}
				}
			}

			img {
				max-width: 100%;
				display: block;
			}
		}
	}

	&-table {
		border-collapse: collapse;
		margin: 1rem 0;
		overflow: visible;
		width: 100%;

		.presentation-page--pdf & {
			height: 250px;
		}

		tbody {
			overflow: visible;
		}

		th {
			border-collapse: collapse;
			padding: 0.5rem;
			border-bottom: 1px solid modules.color_('border');

			#{$presentation}-page--pdf & {
				padding: 0.25rem 0;
			}
		}

		td {
			border-collapse: collapse;
			padding: 0.5rem;
			text-align: center;

			#{$presentation}-page--pdf & {
				font-size: 8px;
				padding: 0.15rem;
			}
		}

		tr {
			&:nth-of-type(even) {
				background-color: rgba(220, 220, 220, 0.2);
			}
			&:hover {
				background-color: rgba(220, 220, 220, 0.5);
			}

			.presentation-page--pdf & {
				&.highlighted {
					td {
						&:nth-of-type(4),
						&:nth-of-type(6) {
							border: 1px solid modules.color_('primary');
							border-radius: 3px;
							color: modules.color_('primary');
							font-size: 10px;
							font-weight: bold;
						}
						&:nth-of-type(6) {
							border: 1px solid modules.color_('primary', 'light');
							color: modules.color_('primary','light');
						}
					}
				}
			}

		}

		.tooltip {
			position: relative;
			overflow: visible;

			&:hover {
				font-weight: bold;
				cursor: help;
				&:after {
					opacity: 1;
				}
			}

			&:after {
				background-color: rgba(modules.color_('background'), 0.9);
				backdrop-filter: blur(1px);
				border: 1px solid modules.color_('border');
				border-radius: modules.$border-radius;
				bottom: 0;
				content: attr(data-title);
				display: block;
				font-size: 13px;
				left: 50%;
				opacity: 0;
				padding: 1rem;
				pointer-events: none;
				position: absolute;
				transition: opacity 150ms modules.$ease-out;
				transform: translateY(-45px) translateX(-50%);
				z-index: 12;
				width: 350px;
			}

			&--bottom {
				&:after {
					bottom: auto;
					top: 0;
					transform: translateY(45px) translateX(-50%);
				}
			}

			&--right {
				&:after {
					left: auto;
					right: 0;
					transform: translateY(45px) translateX(0);
				}
			}
		}
	}

	&__qr {
		max-width: 110px;
	}

	.rate-breakdown {
		margin-left: auto;
		margin-right: auto;
		text-align: right;

		td {
			&:nth-of-type(even) {
				padding-left: 2rem;
			}
		}

		&__sep {
			border-bottom: 1px solid modules.color_('border', 'dark');
		}
	}

	.text--disclaimer {
		font-size: 11px !important;
	}

	.table-notes {
		// border-collapse: collapse;
		border-spacing: 0.5rem;
		display: none;
		align-items: flex-start;
		justify-content: space-between;

		.presentation-page--pdf & {
			display: table;
		}
	}

	.table-note {
		@include modules.gutter('padding', 0.5);
		@include modules.gutter('margin');
		border: 2px solid modules.color_('primary');
		border-radius: 5px;
		display: table-cell;
		font-size: 9px;
		flex: 1;
		vertical-align: top;
		width: 50%;
	}

	&-cover {
		// border: 1px solid modules.color_('primary');
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 740px;

		img {
			max-height: 200px;
			max-width: 200px;
		}
	}

	hr {
		@include modules.gutter('margin-top', 0);
		@include modules.gutter('margin-bottom', 0);
		border-top: 1px solid modules.color_('border', 'light');
		border-bottom: 0;
	}
}
</style>
